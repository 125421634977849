import React, {useContext, useState} from 'react'
import {Components} from 'eyecons-components'
import {Guest, GuestContext} from 'src/layouts'
import * as Requests from 'eyecons-requests'

export default function Home() {
    const {authenticated, authenticatedIsLoading} = useContext(GuestContext)
    const sports = Requests.useSports({hidden: 0, sort: 'activity desc'}, true)

    const channels = Requests.useChannels({
        // sort: ['is_live desc', 'last_content_uploaded desc'], TODO fix sorting
        paginate: 30,
        page: 1,
    }, true)

    const trendingVideos = Requests.useVideos(null, true, true)

    const events = Requests.useEvents({
        paginate: 8,
        published: true,
    }, true)

    const [queryParams, setQueryParams] = useState<any>({
        paginate: 3,
        page: 1,
        sort: 'last_content_uploaded desc',
    })

    const channelsHomepage = Requests.useChannelsHomepage(queryParams, true, true)

    return (
        <div className={'bg-white storybook-home overflow-hidden'}>
            <div className={'bg-background-light flex flex-col gap-4 lg:gap-9 pb-6'}>
                <div className={'bg-purple-500 mb-20 pt-20'}>
                    {events.data.length > 0 && <Components.Home.Live events={events.data}/>}
                    <div className={'-mb-20'}>
                        <Components.Home.Sports sports={sports.data}/>
                    </div>
                </div>

                <Components.Home.ChannelsSmall channels={channels}/>
            </div>

            <div className={'pt-12 lg:pt-16'}>
                <div className="mb-12 lg:mb-16">
                    <Components.Ads.Ad className={'leaderboard-homepage'} type={'leaderboard-billboard'}/>
                </div>

                {trendingVideos.data.length > 0 && (
                    <Components.Home.Channels
                        channel={{
                            id: 'trending',
                            label: 'Populaire video\'s',
                            videos: trendingVideos.data
                        }}
                    />
                )}

                <Components.Home.Calendar sports={sports.data}/>

                {
                    !authenticatedIsLoading &&
                    <Components.Home.CustomChannels authenticated={authenticated} sports={sports.data}/>
                }

                {channelsHomepage.data.map((channel, index) => {
                        return (
                            <React.Fragment key={index}>
                                {
                                    channel?.videos?.length > 0 && (
                                        <Components.Home.Channels channel={channel}/>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                )}

                <Components.Lists.Lazyload
                    loading={channelsHomepage.isLoading}
                    load={channelsHomepage?.meta?.current_page !== channelsHomepage?.meta?.last_page}
                    onEnter={() =>
                        setQueryParams({...queryParams, page: (channelsHomepage?.meta?.current_page || 0) + 1})
                    }
                />
            </div>
        </div>
    )
}


Home.getLayout = function getLayout(page: React.ReactElement) {
    return (
        <Guest>{page}</Guest>
    )
}